@font-face {
    font-family: 'CustomFont';
    src: local('CustomFont'), url('/src/fonts/Oxanium-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

body {
    font-family: 'CustomFont', Arial, sans-serif;
}